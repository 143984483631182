import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './characters.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { IAFKCharacter } from '../../modules/common/model/graphql-types';
import {
  faAsterisk,
  faBriefcaseMedical,
  faEye,
  faHandFist,
  faShield,
  faSortAlphaUp,
  faVirus,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';
import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { AFKCharacter } from '../../modules/afk/common/components/afk-character';
import { RatingBox } from '../../modules/common/components/rating-box';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    }
  }
};

interface IAfkCharacterNodes {
  nodes: IAFKCharacter[];
}

interface IAfkCharacterEntry {
  allCharacters: IAfkCharacterNodes;
}

interface IProps {
  data: IAfkCharacterEntry;
}

const AFKTier: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [tableMode, setTableMode] = useState('Pyramid');
  const [activeFilters, setActiveFilters] = useState(null);
  const [activeText, setActiveText] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'A',
            tooltip: 'A Rarity',
            image: (
              <StaticImage
                src="../../images/afk/icons/rarity_a.png"
                width={24}
                alt="A"
              />
            )
          },
          {
            value: 'S',
            tooltip: 'S Rarity',
            image: (
              <StaticImage
                src="../../images/afk/icons/rarity_s.png"
                width={24}
                alt="S"
              />
            )
          }
        ]
      },
      {
        key: 'faction',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Graveborn',
            tooltip: 'Graveborn',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_grave.png"
                width={24}
                alt="Graveborn"
              />
            )
          },
          {
            value: 'Lightbearer',
            tooltip: 'Lightbearer',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_light.png"
                width={24}
                alt="Lightbearer"
              />
            )
          },
          {
            value: 'Mauler',
            tooltip: 'Mauler',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_mauler.png"
                width={24}
                alt="Mauler"
              />
            )
          },
          {
            value: 'Wilder',
            tooltip: 'Wilder',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_wilder.png"
                width={24}
                alt="Wilder"
              />
            )
          },
          {
            value: 'Celestial',
            tooltip: 'Celestial',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_cele.png"
                width={24}
                alt="Celestial"
              />
            )
          },
          {
            value: 'Hypogean',
            tooltip: 'Hypogean',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_hypo.png"
                width={24}
                alt="Hypogean"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Mage',
            tooltip: 'Mage',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_mage.png"
                width={24}
                alt="Mage"
              />
            )
          },
          {
            value: 'Marksman',
            tooltip: 'Marksman',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_mark.png"
                width={24}
                alt="Marksman"
              />
            )
          },
          {
            value: 'Rogue',
            tooltip: 'Rogue',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_rogue.png"
                width={24}
                alt="Rogue"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_support.png"
                width={24}
                alt="Support"
              />
            )
          },
          {
            value: 'Tank',
            tooltip: 'Tank',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_tank.png"
                width={24}
                alt="Tank"
              />
            )
          },
          {
            value: 'Warrior',
            tooltip: 'Warrior',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_warrior.png"
                width={24}
                alt="Warrior"
              />
            )
          }
        ]
      },
      {
        key: 'damage',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Magic',
            tooltip: 'Magic',
            image: (
              <StaticImage
                src="../../images/afk/icons/damage_magic.png"
                width={24}
                alt="Magic"
              />
            )
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/afk/icons/damage_phys.png"
                width={24}
                alt="Physical"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };

  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };

  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.damage && activeFilters.damage !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.damageType.toLowerCase() === activeFilters.damage.toLowerCase()
        );
      }
      if (activeFilters.range && activeFilters.range !== 'all') {
        filtered = filtered.filter((emp) => emp.range === activeFilters.range);
      }
      if (activeFilters.faction && activeFilters.faction !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.faction &&
            emp.faction.length > 0 &&
            emp.faction.indexOf(activeFilters.faction) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === 'afk') {
      filtered = filtered.sort((a, b) =>
        a.ratings.afk < b.ratings.afk ? 1 : -1
      );
    } else if (sortOption === 'dream_crystal') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_crystal < b.ratings.dream_crystal ? 1 : -1
      );
    } else if (sortOption === 'dream_orson') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_orson < b.ratings.dream_orson ? 1 : -1
      );
    } else if (sortOption === 'dream_overall') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_overall < b.ratings.dream_overall ? 1 : -1
      );
    } else if (sortOption === 'dream_yeti') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_yeti < b.ratings.dream_yeti ? 1 : -1
      );
    } else if (sortOption === 'dream_brute') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_brute < b.ratings.dream_brute ? 1 : -1
      );
    } else if (sortOption === 'dream_creeper') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_creeper < b.ratings.dream_creeper ? 1 : -1
      );
    } else if (sortOption === 'dream_illucia') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_illucia < b.ratings.dream_illucia ? 1 : -1
      );
    } else if (sortOption === 'pvp') {
      filtered = filtered.sort((a, b) =>
        a.ratings.pvp < b.ratings.pvp ? 1 : -1
      );
    } else if (sortOption === 'dream_thal') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_thal < b.ratings.dream_thal ? 1 : -1
      );
    } else if (sortOption === 'dream_wolf') {
      filtered = filtered.sort((a, b) =>
        a.ratings.dream_wolf < b.ratings.dream_wolf ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
    forceUpdate();
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const changeMode = (e) => {
    setTableMode(e);
    if (tableMode === 'Pyramid') {
      setSortOption('dream_overall');
    } else {
      setSortOption('afk');
    }
  };

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: 'afk', label: 'AFK Stages' },
    { value: 'dream_overall', label: 'Dream Realm (Overall)' },
    { value: 'pvp', label: 'PVP' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  useEffect(() => {
    setSortOption('afk');
  }, []);

  return (
    <DashboardLayout className={'generic-page tier-afk '} game="afk">
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/afk/categories/category_tier.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Tier List for best characters</h1>
          <h2>
            Our AFK Journey tier list rates all characters by their performance
            in AFK Stages, Dream Realm and PVP! Create the best team with our
            tier list!
          </h2>
          <p>
            Last updated: <strong>26/03/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="tier-list-page">
        <SectionHeader title="AFK Journey Tier list" />
        <p className="intro">
          The AFK Journey tier list is based on the{' '}
          <strong className="red">patch 1.3.3</strong> (Season 3) and we will
          continue updating it with every balance patch that happens. Please
          keep in mind that AFK Journey{' '}
          <strong>is a game where team building matters most</strong> and while
          in the early game, you can get carried by one or two strong character,
          the further you progress into the game, the less individual strength
          will matter and you will need to build proper teams - based on
          synergy, faction bonuses etc.
        </p>
        <p className="intro">
          Special thanks to{' '}
          <StaticImage
            src="../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> community that
          supports AFK Arena and AFK Journey. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . They do a lot of theorycrafting and testing for AFK Journey and
          their findings helped us shape the tier list.
        </p>
        <Accordion className="tier-list-accordion">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Tier list criteria</Accordion.Header>
            <Accordion.Body>
              <p>
                Since in AFK Journey there's a lot of different modes, and some
                characters shine only in some of them, we have decided to split
                the ratings between a number of tier lists:
              </p>
              <ul>
                <li>
                  <strong>AFK Stages</strong> - how the character performs in
                  AFK Stages - so the main progression of the game. As long as
                  you're playing below or on equal power level as the enemy
                  team, you won't have that much issues with passing the stage,
                  but the moment you have to fight higher power and level
                  enemies, things change. This rating shows how good the
                  characters are when fighting way below enemy power level.
                  Characters rated at{' '}
                  <strong className="supreme">Supreme+</strong> (all skills
                  unlocked and EX weapon at +10).
                </li>
                <li>
                  <strong>Dream Realm</strong> - how the character performs in
                  the boss-killing mode where you get better rewards for doing
                  more damage to them. Each of the four available bosses has its
                  own rating as they require unique approaches to tackle them,
                </li>
                <ul>
                  <li>
                    For all bosses we're currently looking at them from the late
                    game perspective, so{' '}
                    <strong className="supreme">Supreme+</strong> (all skills
                    unlocked and EX weapon at +10).
                  </li>
                </ul>
                {/* <li>
                  <strong>Battle Drills</strong> - how the character performs in
                  Battle Drill. This rating averages the performance in trash
                  nodes and against the bosses. Characters rated at{' '}
                  <strong className="supreme">Supreme+</strong> (all skills
                  unlocked and EX weapon at +10).
                </li> */}
                <li>
                  <strong>PVP</strong> - how the character performs in PVP.
                  Characters rated at{' '}
                  <strong className="supreme">Supreme+</strong> (all skills
                  unlocked and EX weapon at +10).
                </li>
              </ul>
              <p>Character categories:</p>
              <ul className="hsr-categories">
                <li>
                  <strong className="dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    Dealer
                  </strong>{' '}
                  - Characters that focus on dealing damage whose kit mainly
                  revolves around that,
                </li>
                <li>
                  <strong className="specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </strong>{' '}
                  - Characters that are hybrids - combination of other roles.
                  Most often they are damage dealers who can provide buffs to
                  allies, debuff enemies or Crowd Control them,
                </li>
                <li>
                  <strong className="support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Supports
                  </strong>{' '}
                  - Characters that focus on healing their allies or providing
                  them with buffs (or both at the same time),
                </li>
                <li>
                  <strong className="tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tanks
                  </strong>{' '}
                  - Characters that are the vanguard of the team and focus on
                  keeping their allies safe by standing in front of them.
                </li>
              </ul>
              <p>Our ratings explained:</p>
              <ul>
                <li>
                  <strong>S+</strong> - The character is simply overpowered and
                  dominates in their role,
                </li>
                <li>
                  <strong>S</strong> - a great and versatile character that has
                  barely any flaws,
                </li>
                <li>
                  <strong>A</strong> - a good character that has a few little
                  flaws,
                </li>
                <li>
                  <strong>B</strong> - a decent character that has a few big
                  flaws holding them back,
                </li>
                <li>
                  <strong>C</strong> - underwhelming character.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Changelog</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>26/03/2025</strong>
              </p>
              <ul>
                <li>
                  Added the <strong>[Athalia]</strong> to the tier list.
                </li>
              </ul>
              <p>
                <strong>17/03/2025</strong>
              </p>
              <ul>
                <li>
                  Added the <strong>[Gerda]</strong> to the tier list.
                </li>
              </ul>
              <p>
                <strong>07/03/2025</strong>
              </p>
              <p>
                Updated the AFK and PVP tier list based on the latest meta
                changes.
              </p>
              <ul>
                <li>AFK Stages:</li>
                <ul>
                  <li>Lenya A -&gt; B (Squishy at high deficit)</li>
                  <li>
                    Bryon B -&gt; A (Good tech choice against occasional Lily
                    May; better than Niru for this purpose thanks to his damage
                    potential)
                  </li>
                  <li>
                    Talene S+ -&gt; S (She is good but no meta pushing team
                    specifically needs her)
                  </li>
                  <li>
                    Bonnie S -&gt; S+ (Really good damage, used in many teams)
                  </li>
                  <li>
                    Cryonaia S -&gt; A (Debuffs her team, sometimes runs out of
                    time)
                  </li>
                  <li>
                    Nara S -&gt; A (Tech choice where you need to pull an enemy
                    unit)
                  </li>
                  <li>Sonja S -&gt; A (Squishy at high deficit)</li>
                  <li>
                    Cyran A -&gt; S (Does what Lumont was used for in AFK
                    stages, but better)
                  </li>
                  <li>Sinbad B -&gt; A (Good tech choice for his debuffs)</li>
                  <li>
                    Twins S -&gt; S+ (Works in most teams, really good AFK
                    pushing unit)
                  </li>
                  <li>
                    Lorsan S -&gt; A (Enemies at high deficit are too tanky for
                    him to get kills)
                  </li>
                  <li>
                    Damian A -&gt; S (Good realiable healing that won't die too
                    early like others, his haste is good in Eironn teams)
                  </li>
                  <li>
                    Ludovic B -&gt; A (Similar usage to Damian but not as good,
                    his CC is still useful)
                  </li>
                  <li>
                    {' '}
                    Lumont S -&gt; B (Fell off with the introduction of Cyran)
                  </li>
                  <li>
                    Lucca S -&gt; A (Doesn't offer much utility, but is still
                    very tanky)
                  </li>
                  <li>
                    Callan A -&gt; S (Protects team from getting one-shot early,
                    better than Brutus)
                  </li>
                  <li>
                    Lucius B -&gt; A (Used in Lightbearer teams with Rowan)
                  </li>
                </ul>
                <li>PVP:</li>
                <ul>
                  <li>
                    Igor C -&gt; A (With Scarlita losing usage, Igor is once
                    again hard to kill without specific answers)
                  </li>
                  <li>
                    Satrana B -&gt; A (Her magic damage resist is useful in
                    current meta where many carries rely on magic dmg)
                  </li>
                  <li>
                    Sonja A -&gt; B (Awkward placement in most arena maps)
                  </li>
                  <li>
                    Temesia A -&gt; B (Not as good this season, there are better
                    options for her role)
                  </li>
                  <li>
                    Nara S -&gt; A (Not as good as Silvina or Vala as an
                    assassin)
                  </li>
                  <li>Bryon B -&gt; A (Tech choice against Lily May)</li>
                  <li>
                    Arden A -&gt; S (Still a really good DPS carry in Eironn
                    teams)
                  </li>
                  <li>
                    Lenya A -&gt; S (Strong with Stormstrike artifact combo)
                  </li>
                  <li>
                    Silvina A -&gt; S (Strong with Stormstrike artifact combo)
                  </li>
                  <li>Niru B -&gt; A (Tech choice against Lily May)</li>
                  <li>
                    Twins A -&gt; S (If they get their ultimate out the team
                    basically wins the game)
                  </li>
                  <li>
                    Hugin A -&gt; S (Got better with the introduction of Cyran
                    making Lightbearer teams more viable)
                  </li>
                  <li>
                    Smokey S -&gt; A (Too easy to interrupt with how many
                    control options are meta right now)
                  </li>
                </ul>
              </ul>
              <p>
                <strong>28/02/2025</strong>
              </p>
              <ul>
                <li>
                  Added the <strong>[Cyran]</strong> to the tier list.
                </li>
              </ul>
              <p>
                <strong>21/02/2025</strong>
              </p>
              <ul>
                <li>
                  Added the <strong>[Cryonaia]</strong> to the tier list.
                </li>
              </ul>
              <p>
                <strong>17/02/2025</strong>
              </p>
              <ul>
                <li>
                  Dream Realm tier lists have been updated to the Endless meta.
                </li>
              </ul>
              <p>
                <strong>14/02/2025</strong>
              </p>
              <ul>
                <li>
                  Added the <strong>[Callan]</strong> to the tier list.
                </li>
              </ul>
              <p>
                <strong>03/02/2025</strong>
              </p>
              <ul>
                <li>
                  Added the <strong>[Valka]</strong> to the tier list.
                </li>
              </ul>
              <p>
                <strong>20/01/2025</strong>
              </p>
              <ul>
                <li>
                  Added the <strong>[Twins]</strong> to the tier list.
                </li>
                <li>
                  Lowered <strong>[Lorsan]</strong> ratings half a tier from S+
                  to S in AFK and PVP. While he can be slotted in multiple
                  existing teams in both modes and he improves their performance
                  - or introduces new variants - he's never the key element in
                  them that makes them tick which is what S+ rating entails.
                </li>
                <ul>
                  <li>
                    This is the first time we are adjusting the ratings down in
                    that way - the PTR server it seems isn't that reliable when
                    it comes to properly testing a character. Moving forward, we
                    won't rely on it anymore. Instead, as usual we will wait for
                    the character to release in the Global server, test it
                    there, and only then add a review and rate the character.
                  </li>
                </ul>
              </ul>
              <p>
                <strong>16/01/2025</strong>
              </p>
              <p>Tier list has been revamped for Season 3.</p>
              <p>
                <strong>03/01/2025</strong>
              </p>
              <ul>
                <li>
                  <strong>[Bonnie]</strong> has been added to the tier list.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
        <div className="mode-switcher">
          <div className="tier-list-switcher type">
            <div
              className={`option pyramid-variant ${
                tableMode === 'Pyramid' && 'selected'
              }`}
              onClick={() => changeMode('Pyramid')}
            >
              <h6>Generic Tier List</h6>
            </div>
            <div
              className={`option table-variant ${
                tableMode === 'Table' && 'selected'
              }`}
              onClick={() => changeMode('Table')}
            >
              <h6>Dream Realm Only</h6>
            </div>
          </div>
        </div>
        {tableMode === 'Table' ? (
          <>
            <div className="tier-list-header">
              <p className="title">
                Tier List <span>(Dream Realm Only)</span>
              </p>
              <p className="sub-title">
                You're currently viewing the Dream Realm view that shows the
                ratings for each of the 8 available bosses in Season 3 for the
                Endless mode. For other modes, switch to the second category.
              </p>
            </div>
            {/* <Alert variant="primary alert-red ">
              <p>
                <strong>
                  The current Dream Realm ratings are based on the Pre-Endless
                  Dream Realm
                </strong>{' '}
                - in the test server, it was impossible to reach Endless due to
                its short duration. True Damage and HP-based characters (for
                example: Lily May or Phraesto) underperform in Pre-Endless due
                to how the boss defenses and HP scales. They will perform better
                in Endless mode and once we reach it (should take around 40 days
                into the season), the ratings will be updated.
              </p>
            </Alert> */}
            <div className="employees-filter-bar afk table-view">
              <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
            </div>
            <div className="tier-list-table">
              <div className="tier-list-header">
                <div className="tier-list-header-bottom">
                  <div
                    className={`column character ${
                      sortOption === '+fullName' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('+fullName')}
                  >
                    <p>
                      Character{' '}
                      {sortOption && sortOption === '+fullName' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column ${
                      sortOption === 'dream_overall' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_overall')}
                  >
                    <p>
                      Overall{' '}
                      {sortOption && sortOption === 'dream_overall' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_yeti' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_yeti')}
                  >
                    <p>
                      Snow Stomper{' '}
                      {sortOption && sortOption === 'dream_yeti' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_wolf' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_wolf')}
                  >
                    <p>
                      Lone Gaze{' '}
                      {sortOption && sortOption === 'dream_wolf' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_orson' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_orson')}
                  >
                    <p>
                      Orson{' '}
                      {sortOption && sortOption === 'dream_orson' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_crystal' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_crystal')}
                  >
                    <p>
                      Crystal Beetle{' '}
                      {sortOption && sortOption === 'dream_crystal' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_brute' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_brute')}
                  >
                    <p>
                      Shellbrute{' '}
                      {sortOption && sortOption === 'dream_brute' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_creeper' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_creeper')}
                  >
                    <p>
                      Creeper{' '}
                      {sortOption && sortOption === 'dream_creeper' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_illucia' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_illucia')}
                  >
                    <p>
                      Illucia{' '}
                      {sortOption && sortOption === 'dream_illucia' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div
                    className={`column dream ${
                      sortOption === 'dream_thal' ? 'active' : ''
                    }`}
                    onClick={() => setSortOption('dream_thal')}
                  >
                    <p>
                      Thalassa{' '}
                      {sortOption && sortOption === 'dream_thal' && (
                        <span className="icon">
                          <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="column info">
                    <p>Info</p>
                  </div>
                </div>
              </div>
              <div className="tier-list-content">
                {visibleCharacters.map((emp, index) => (
                  <div key={index}>
                    <div className="tier-list-row">
                      <div className="column character">
                        <AFKCharacter
                          slug={emp.slug}
                          mode="icon"
                          showLabel
                          enablePopover
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_overall}
                          ratingName="Overall"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_yeti}
                          ratingName="Snow Stomper"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_wolf}
                          ratingName="Lone Wolf"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_orson}
                          ratingName="Orson"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_crystal}
                          ratingName="Crystal Beetle"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_brute}
                          ratingName="Sheelbrute"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_creeper}
                          ratingName="Creeper"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_illucia}
                          ratingName="Illucia"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column dream">
                        <RatingBox
                          game="afk"
                          rating={emp.ratings.dream_thal}
                          ratingName="Thalassa"
                          modeCompact
                          showName
                        />
                      </div>
                      <div className="column review">
                        {activeText === emp.slug ? (
                          <p onClick={() => setActiveText(null)}>
                            <FontAwesomeIcon icon={faXmark} width="18" />{' '}
                            <span>Hide</span>
                          </p>
                        ) : (
                          <p onClick={() => setActiveText(emp.slug)}>
                            <FontAwesomeIcon icon={faEye} width="18" />{' '}
                            <span>Show</span>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="review-mobile">
                      {activeText === emp.slug ? (
                        <p onClick={() => setActiveText(null)}>
                          <FontAwesomeIcon icon={faXmark} width="18" />{' '}
                          <span>Hide info</span>
                        </p>
                      ) : (
                        <p onClick={() => setActiveText(emp.slug)}>
                          <FontAwesomeIcon icon={faEye} width="18" />{' '}
                          <span>Show info</span>
                        </p>
                      )}
                    </div>
                    <div
                      className={`explanation ${
                        activeText === emp.slug ? 'visible' : ''
                      }`}
                    >
                      {emp.tierExplanation ? (
                        <>{renderRichText(emp.tierExplanation, options)}</>
                      ) : (
                        <p>Explanation missing.</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tier-list-header">
              <p className="title">
                Tier List -{' '}
                <span>
                  {sortOption === 'afk' && 'AFK Stages'}
                  {sortOption === 'dream_overall' && 'Dream Realm (Overall)'}
                  {sortOption === 'battle' && 'Battle Drill'}
                  {sortOption === 'pvp' && 'PVP'}
                </span>
              </p>
              <p className="sub-title">
                You're currently viewing the{' '}
                <strong className="tier-type">
                  {sortOption === 'afk' && 'AFK Stages'}
                  {sortOption === 'dream_overall' && 'Dream Realm (Overall)'}
                  {sortOption === 'battle' && 'Battle Drill'}
                  {sortOption === 'pvp' && 'PVP'}
                </strong>{' '}
                tier list. It shows{' '}
                {sortOption === 'afk' &&
                  'how the character performs in the Story and AFK Stages after obtaining their EX Weapon and under a high deficit.'}
                {sortOption === 'battle' &&
                  'how the character performs in Battle Drill. This rating averages the performance in trash nodes and against the bosses.'}
                {sortOption === 'dream_overall' && (
                  <>
                    how the character performs in{' '}
                    <strong className="red">Dream Realm (Endless)</strong>.
                  </>
                )}
                {sortOption === 'pvp' &&
                  'how the character performs in PVP - both offense and defense.'}{' '}
                Use the switcher below to view a different tier list.
              </p>
            </div>
            <div className="tier-list-switcher">
              <div
                className={`option afk ${sortOption === 'afk' && 'selected'}`}
                onClick={() => setSortOption('afk')}
              >
                <h6>AFK Stages (Deficit)</h6>
              </div>
              {/* <div
                className={`option battle ${
                  sortOption === 'battle' && 'selected'
                }`}
                onClick={() => setSortOption('battle')}
              >
                <h6>Battle Drills</h6>
              </div> */}
              <div
                className={`option dream-overall ${
                  sortOption === 'dream_overall' && 'selected'
                }`}
                onClick={() => setSortOption('dream_overall')}
              >
                <h6>Dream Realm (Overall)</h6>
              </div>
              <div
                className={`option pvp ${sortOption === 'pvp' && 'selected'}`}
                onClick={() => setSortOption('pvp')}
              >
                <h6>PVP</h6>
              </div>
            </div>
            {/* <Alert variant="primary alert-red ">
              <p>
                <strong>
                  The current Dream Realm ratings are based on the Pre-Endless
                  Dream Realm
                </strong>{' '}
                - in the test server, it was impossible to reach Endless due to
                its short duration. True Damage and HP-based characters (for
                example: Lily May or Phraesto) underperform in Pre-Endless due
                to how the boss defenses and HP scales. They will perform better
                in Endless mode and once we reach it (should take around 40 days
                into the season), the ratings will be updated.
              </p>
            </Alert> */}
            <div className="employees-filter-bar afk">
              <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
            </div>
            <div className="custom-tier-list-afk">
              <div className="custom-tier-header">
                <div className="tier-rating">
                  <span>&nbsp;</span>
                </div>
                <div className="custom-tier-container">
                  <div className="custom-header dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-header specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-header support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-header tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                </div>
              </div>
              <div className="custom-tier tier-s-plus first">
                <div className="tier-rating s-plus">
                  <span>S+</span>
                </div>
                <div className="custom-tier-container">
                  <div className="burst-type-mobile dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-tier-burst dps">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 10)
                      .filter((emp) => emp.tierListCategory === 'DPS')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-tier-burst specialist">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 10)
                      .filter((emp) => emp.tierListCategory === 'Specialist')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-tier-burst support">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 10)
                      .filter((emp) => emp.tierListCategory === 'Support')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                  <div className="custom-tier-burst tank">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 10)
                      .filter((emp) => emp.tierListCategory === 'Tank')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="custom-tier tier-s">
                <div className="tier-rating s">
                  <span>S</span>
                </div>
                <div className="custom-tier-container">
                  <div className="burst-type-mobile dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-tier-burst dps">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 9)
                      .filter((emp) => emp.tierListCategory === 'DPS')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-tier-burst specialist">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 9)
                      .filter((emp) => emp.tierListCategory === 'Specialist')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-tier-burst support">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 9)
                      .filter((emp) => emp.tierListCategory === 'Support')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                  <div className="custom-tier-burst tank">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 9)
                      .filter((emp) => emp.tierListCategory === 'Tank')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297007"
              ></div>
              <div className="custom-tier tier-a">
                <div className="tier-rating a">
                  <span>A</span>
                </div>
                <div className="custom-tier-container">
                  <div className="burst-type-mobile dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-tier-burst dps">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 8)
                      .filter((emp) => emp.tierListCategory === 'DPS')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-tier-burst specialist">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 8)
                      .filter((emp) => emp.tierListCategory === 'Specialist')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-tier-burst support">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 8)
                      .filter((emp) => emp.tierListCategory === 'Support')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                  <div className="custom-tier-burst tank">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 8)
                      .filter((emp) => emp.tierListCategory === 'Tank')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="custom-tier tier-b">
                <div className="tier-rating b">
                  <span>B</span>
                </div>
                <div className="custom-tier-container">
                  <div className="burst-type-mobile dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-tier-burst dps">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 7)
                      .filter((emp) => emp.tierListCategory === 'DPS')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-tier-burst specialist">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 7)
                      .filter((emp) => emp.tierListCategory === 'Specialist')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-tier-burst support">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 7)
                      .filter((emp) => emp.tierListCategory === 'Support')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                  <div className="custom-tier-burst tank">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 7)
                      .filter((emp) => emp.tierListCategory === 'Tank')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="custom-tier tier-c">
                <div className="tier-rating c">
                  <span>C</span>
                </div>
                <div className="custom-tier-container">
                  <div className="burst-type-mobile dps">
                    <FontAwesomeIcon icon={faHandFist} width="18" /> Damage
                    dealer
                  </div>
                  <div className="custom-tier-burst dps">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 6)
                      .filter((emp) => emp.tierListCategory === 'DPS')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile specialist">
                    <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                  </div>
                  <div className="custom-tier-burst specialist">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 6)
                      .filter((emp) => emp.tierListCategory === 'Specialist')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile support">
                    <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                    Support
                  </div>
                  <div className="custom-tier-burst support">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 6)
                      .filter((emp) => emp.tierListCategory === 'Support')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                  <div className="burst-type-mobile tank">
                    <FontAwesomeIcon icon={faShield} width="18" /> Tank
                  </div>
                  <div className="custom-tier-burst tank">
                    {visibleCharacters
                      .filter((emp) => emp.ratings[sortOption] === 6)
                      .filter((emp) => emp.tierListCategory === 'Tank')
                      .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                      .map((emp, index) => {
                        return (
                          <span key={index}>
                            <Card className="avatar-card">
                              <AFKCharacter
                                slug={emp.slug}
                                mode="icon"
                                showLabel
                                showTags
                                showIcon
                                enablePopover
                              />
                            </Card>
                          </span>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="fuse-ad-placeholder" data-fuse="22844297007"></div>
      </div>
    </DashboardLayout>
  );
};

export default AFKTier;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | AFK Journey | Prydwen Institute"
    description="Our AFK Journey tier list rates all characters by their performance in AFK Stages, Dream Realm and PVP! Create the best team with our tier list!"
    game="afk"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulAfkCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        class
        faction
        damageType
        range
        tierListCategory
        tierListTags
        tierExplanation {
          raw
        }
        ratings {
          afk
          pvp
          dream_overall
          dream_yeti
          dream_orson
          dream_crystal
          dream_wolf
          dream_brute
          dream_creeper
          dream_illucia
          dream_thal
        }
      }
    }
  }
`;
